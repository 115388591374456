import React, { useEffect, useRef } from "react";
import NavBar from "./NavBar";
import AboutFuzionest from "../components/AboutFuzionest";
import News from "../components/News";
import ContactForm from "../components/contactForm";
import FooterFive from "../components/footerFive";
import Services from "./service";
import config from "../utils/config";

const HeroSection = () => {
  const innerCircleRef = useRef(null);
  const heroImageRef = useRef(null);

  useEffect(() => {
    if (innerCircleRef.current && heroImageRef.current) {
      const handleLoad = () => {
        innerCircleRef.current.style.animation =
          "revealCircle 2s ease-in-out forwards";
        setTimeout(() => {
          heroImageRef.current.style.clipPath = "circle(100% at 50% 50%)";
        }, 100); // Delay to ensure the animation starts after the page has loaded
      };

      window.addEventListener("load", handleLoad);

      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  return (
    <>
      <NavBar />

      <div className="unique-hero">
        <video
          src={`${config.baseUrl}/assets/video/FuzionestVideo.mp4`}
          alt="Hero"
          className="unique-hero-image"
          ref={heroImageRef}
          autoPlay={!window.matchMedia("(max-width: 768px)").matches}
          poster={`${config.baseUrl}/img/banner/hero-banner.png`}
          loop
          muted
        />
        <div
          id="unique-inner-circle"
          className="unique-inner-circle"
          ref={innerCircleRef}
        ></div>
      </div>

      {/* <Services /> */}
      <AboutFuzionest />
      <News />
      <ContactForm />
      <FooterFive />
    </>
  );
};

export default HeroSection;
