import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import FlutterBlog from "../components/flutterBlog";
import FlutterBlogContent from "../components/FlutterBlogContent";

const BlogPage = () => {
  return (
    <>
      <NavBar />
      <FlutterBlog />
      <FlutterBlogContent />
      {/* <Rslpagethree /> */}
      <Footer />
    </>
  );
};

export default BlogPage;
