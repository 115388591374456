import React from "react";
import config from "../utils/config";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-5">
        <div className="container">
          <div className="row">
            <div
              className="section-title "
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 20%",
                marginTop: "35%",
              }}
            >
              <h2
                className="title"
                style={{
                  fontSize: "40px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Why we're Different
              </h2>
              <p
                className="content"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "black",
                  textAlign: "center",
                  lineHeight: "120%",
                }}
              >
                Experience the power of Uber-like App Development Solution
                catering to over 300,000 on-demand customers
              </p>
              <div className="row" style={{ margin: "10px 10px" }}>
                <div className="col-md-6 mb-4">
                  <div className="single-about-inner service-imgtext">
                    <div className="thumb mb-3 mb-4">
                      <img
                        src="assets/img/icon/111.png"
                        alt="White Label & Customization"
                        style={{ width: "40px" }}
                      />
                    </div>

                    <h5>White Label & Customization</h5>
                    <p style={{ lineHeight: "140%", color: "#455065" }}>
                      Get fully white labeled on-demnad solution with your
                      branding & logo, along with custimizations for both iOS
                      and Andriod.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="single-about-inner service-imgtext">
                    <div className="thumb mb-3 mb-4">
                      <img
                        // src="assets/img/icon/33.png"
                        src={`${config.baseUrl}/assets/img/icon/33.png`}
                        alt="Scalable & Cost Effertive"
                        style={{ width: "40px" }}
                      />
                    </div>
                    <h5>Scalable & Cost Effertive</h5>
                    <p style={{ lineHeight: "140%", color: "#455065" }}>
                      Our Robust Technologies will be tailored to your on-demand
                      business unique experience. needs, delivering a truly
                    </p>
                  </div>
                </div>

                <div className="col-md-6 mb-4">
                  <div className="single-about-inner service-imgtext">
                    <div className="thumb mb-3 mb-4">
                      <img
                        // src="assets/img/icon/66.png"
                        src={`${config.baseUrl}/assets/img/icon/66.png`}
                        alt="24/7 Customer Suppport"
                        style={{ width: "40px" }}
                      />
                    </div>
                    <h5>24/7 Customer Suppport</h5>
                    <p style={{ lineHeight: "140%", color: "#455065" }}>
                      Dedicated account manager to deal Get 24/7 technical
                      support and a with all of your issues instantly.
                    </p>
                  </div>
                </div>

                <div className="col-md-6 mb-4">
                  <div className="single-about-inner service-imgtext">
                    <div className="thumb mb-3 mb-4">
                      <img
                        // src="assets/img/icon/55.png"
                        src={`${config.baseUrl}/assets/img/icon/55.png`}
                        alt="Uber Like In-App Chat"
                        style={{ width: "40px", alignItems: "center" }}
                      />
                    </div>
                    <h5>Uber Like In-App Chat</h5>
                    <p style={{ lineHeight: "140%", color: "#455065" }}>
                      Stay instantly accessible to your customers through
                      real-time in-app chat, facilitating seamless communication
                      between clients and agents.
                    </p>
                  </div>
                </div>

                <div className="col-md-6 mb-4">
                  <div className="single-about-inner service-imgtext">
                    <div className="thumb mb-3 mb-4">
                      <img
                        // src="assets/img/icon/22.png"
                        src={`${config.baseUrl}/assets/img/icon/22.png`}
                        alt="Powerful Analytics"
                        style={{ width: "40px" }}
                      />
                    </div>
                    <h5>Powerful Analytics</h5>
                    <p style={{ lineHeight: "140%", color: "#455065" }}>
                      Get real-time insights & generate sates reports through
                      the admin panel which helps you to grow your on-demand
                      business.
                    </p>
                  </div>
                </div>

                <div className="col-md-6 mb-4 service-imgtext">
                  <div className="single-about-inner service-imgtext">
                    <div className="thumb mb-3 mb-4">
                      <img
                        // src="assets/img/icon/44.png"
                        src={`${config.baseUrl}/assets/img/icon/44.png`}
                        alt="Go Live In No Time"
                        style={{ width: "40px" }}
                      />
                    </div>
                    <h5>Go Live In No Time</h5>
                    <p style={{ lineHeight: "140%", color: "#455065" }}>
                      You can easily and swiftly transition to a live state,
                      allowing you to connect and engage in no time with your
                      audience or users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
