import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import config from "../utils/config";

const Homepageone = () => {
  const circleRef = useRef(null);
  const heroVideoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoLoaded = useCallback(() => {
    setIsVideoLoaded(true);
    const circle = circleRef.current;
    const heroVideo = heroVideoRef.current;

    if (circle && heroVideo) {
      requestAnimationFrame(() => {
        circle.style.animation = "revealCircle 1.6s ease-in-out forwards";
        heroVideo.style.transition = "clip-path 1.6s ease-in-out";
        heroVideo.style.clipPath = "circle(100% at 50% 50%)";
      });

      heroVideo.addEventListener("transitionend", () => {
        heroVideo.style.clipPath = "none";
      });
    }
  }, []);

  const handleCanPlayThrough = useCallback(() => {
    setIsVideoPlaying(true);
  }, []);

  useEffect(() => {
    const heroVideo = heroVideoRef.current;

    if (heroVideo) {
      heroVideo.addEventListener("loadeddata", handleVideoLoaded);
      heroVideo.addEventListener("canplaythrough", handleCanPlayThrough);

      return () => {
        heroVideo.removeEventListener("loadeddata", handleVideoLoaded);
        heroVideo.removeEventListener("canplaythrough", handleCanPlayThrough);
      };
    }
  }, [handleVideoLoaded, handleCanPlayThrough]);

  return (
    <div className="hero banner-area bg-cover">
      <video
        src={`${config.baseUrl}/assets/video/FuzionestVideo.mp4`}
        alt="Fuzionest"
        className="hero-image"
        ref={heroVideoRef}
        autoPlay={!window.matchMedia("(max-width: 768px)").matches}
        poster={`${config.baseUrl}/assets/img/banner/hero-banner.png`}
        loop
        muted
        preload="metadata"
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
      {!isVideoPlaying && <div className="loading-placeholder"></div>}
      {isVideoLoaded && (
        <div className="hero-content">
          <h1 data-aos="fade-down">Redefining IT Functions</h1>
          <p data-aos="fade-up" data-aos-delay="200">
            In the vision of transforming business processes through GenAI
          </p>
          <Link to="/technology">
            <button
              className="btn btn-base border-radius-0"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              Learn More
            </button>
          </Link>
        </div>
      )}

      <div className="inner-circle" ref={circleRef}></div>
    </div>
  );
};

export default Homepageone;
