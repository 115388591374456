import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../utils/config";

const RapidCustomisationContent = () => {
  const rapidCustomImgs = [
    "tailored-solution",
    "accelarated-development",
    "role-based",
  ];
  const erpImgs = [
    "comprehensive",
    "scalable-architechture",
    "user-interface",
    "seamless-integration",
    "analytics"
  ];

  const [activeIndexRapid, setActiveIndexRapid] = useState(0);
  const [activeIndexErp, setActiveIndexErp] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndexRapid(
        (prevIndex) => (prevIndex + 1) % rapidCustomImgs.length
      );
      setActiveIndexErp((prevIndex) => (prevIndex + 1) % erpImgs.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div className="bg-cover pd-top-90">
        <div className="">
          <div className="d-flex justify-content-center position-relative">
            <div className="eclipse"></div>
            <div className="shadow-box">
              <div className="dots">
                <img
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                  alt="handshake"
                />
              </div>
              <div className="decorate">
                <img
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-1.png`}
                  alt="handshake"
                />
              </div>
              <div className="multi-tenancy-img">
                <img
                  className="multi-tenancy"
                  src={`${config.baseUrl}/assets/img/rapid-customization/rapid-customization.png`}
                  alt="multi-tenancy"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section-content position-relative py-4 my-4">
          <img
            className="main-img"
            src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-group.png`}
            alt="Future Oriented"
            style={{ width: "100%" }}
          />
          <h2 className="float-content" style={{ fontWeight: "600" }}>
            Why Rapid Customization?
          </h2>
        </div>

        <div className="container">
          <div className="row g-2 pd-top-10 px-2 justify-content-center align-items-center">
            <div
              className="col-xl-6 col-lg-6 col-md-12 col-md-12 order-lg-last order-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-content">
                <div className="d-flex gap-lg-3 gap-1 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={200}
                      className={` ${
                        0 === activeIndexRapid ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={100}
                      className={` ${
                        0 === activeIndexRapid ? "active-index" : "inactive"
                      }`}
                    />
                  </div>
                  <p
                    className={`text-justify ${
                      0 === activeIndexRapid ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Tailored Solutions:</b> Every business is unique, and so
                    are its needs. Off-the-shelf software often falls short in
                    addressing specific requirements. Our rapid customization
                    approach ensures that your ERP or back office system is
                    tailored to your unique workflows, processes, and
                    objectives, providing a perfect fit for your organization.
                  </p>
                </div>
              </div>
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={200}
                      className={`${
                        1 === activeIndexRapid ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={100}
                      className={`${
                        1 === activeIndexRapid ? "active-index" : "inactive"
                      }`}
                    />
                  </div>

                  <p
                    className={`text-justify ${
                      1 === activeIndexRapid ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Accelerated Deployment: </b>Time is of the essence in the
                    competitive business landscape. Traditional ERP
                    implementations can take months or even years. With our
                    rapid customization, we streamline the development and
                    deployment process, significantly reducing the time to go
                    live. This means you can start reaping the benefits of your
                    new system much sooner.
                  </p>
                </div>
              </div>
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={200}
                      className={`${
                        2 === activeIndexRapid ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={100}
                      className={`${
                        2 === activeIndexRapid ? "active-index" : "inactive"
                      }`}
                    />
                  </div>
                  <p
                    className={`text-justify ${
                      2 === activeIndexRapid ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Role-Based Access Control (RBAC):</b> Security and
                    efficiency go hand in hand. Our solutions feature robust
                    Role-Based Access Control (RBAC), ensuring that users have
                    access only to the information and functions they need. This
                    enhances security by minimizing the risk of unauthorized
                    access and ensures that your team can work efficiently
                    without unnecessary interruptions.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 d-flex row g-2"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 d-flex justify-content-center justify-content-lg-start">
                <div className="shadow-box-2">
                  <div className="dots">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                      alt="dots"
                    />
                  </div>
                  <div className="star-1">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star.png`}
                      alt="star"
                    />
                  </div>
                  <img
                    className="main-img"
                    src={`${config.baseUrl}/assets/img/rapid-customization/${rapidCustomImgs[activeIndexRapid]}.png`}
                    alt="Innovative Solution"
                    height={400}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-content position-relative py-4 my-4">
          <img
            className="main-img"
            src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-group.png`}
            alt="Future Oriented"
            style={{ width: "100%" }}
          />
          <h2 className="float-content" style={{ fontWeight: "600" }}>
            Key Features of Our Customized ERP and Back Office Solutions
          </h2>
        </div>

        <div className="container">
          <div className="row g-2 pd-top-10 px-2">
            <div
              className="col-xl-6 col-lg-6 col-md-12 order-lg-last d-flex justify-content-end align-items-center"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
                <div className="shadow-box-2">
                  <div className="dots">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                      alt="handshake"
                    />
                  </div>
                  {/* <div className="star-1">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star.png`}
                      alt="handshake"
                    />
                  </div> */}
                  <div className="decorate-right">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-3.png`}
                      alt="left-arrow"
                    />
                  </div>
                  <img
                    className="main-img"
                    src={`${config.baseUrl}/assets/img/rapid-customization/${erpImgs[activeIndexErp]}.png`}
                    alt="Customized ERP"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-12 d-flex row g-2 justify-content-center align-items-center"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={150}
                      className={`${
                        0 === activeIndexErp ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={60}
                      className={`${
                        0 === activeIndexErp ? "active-index" : "inactive"
                      }`}
                    />
                  </div>

                  <p
                    className={`text-justify ${
                      0 === activeIndexErp ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Comprehensive Role-Based Access Control:</b> Our RBAC
                    framework is designed to provide granular control over user
                    access. This ensures that sensitive information is protected
                    and only accessible to authorized personnel, thereby
                    enhancing overall security and compliance.
                  </p>
                </div>
              </div>
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={150}
                      className={`${
                        1 === activeIndexErp ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={60}
                      className={`${
                        1 === activeIndexErp ? "active-index" : "inactive"
                      }`}
                    />
                  </div>
                  <p
                    className={`text-justify ${
                      1 === activeIndexErp ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Scalable Architecture:</b>Our solutions are built to grow
                    with your business. Whether you are a small enterprise or a
                    large corporation, our ERP and back office systems can scale
                    seamlessly to accommodate your expanding needs, ensuring
                    long-term viability and performance.
                  </p>
                </div>
              </div>
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={150}
                      className={`${
                        2 === activeIndexErp ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={60}
                      className={`${
                        2 === activeIndexErp ? "active-index" : "inactive"
                      }`}
                    />
                  </div>
                  <p
                    className={`text-justify ${
                      2 === activeIndexErp ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Intuitive User Interface:</b> A user-friendly interface
                    is crucial for the successful adoption of any software. Our
                    systems feature intuitive, easy-to-navigate interfaces that
                    reduce the learning curve and enhance user productivity.
                  </p>
                </div>
              </div>
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={150}
                      className={`${
                        3 === activeIndexErp ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={60}
                      className={`${
                        3 === activeIndexErp ? "active-index" : "inactive"
                      }`}
                    />
                  </div>
                  <p
                    className={`text-justify ${
                      3 === activeIndexErp ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Seamless Integration:</b> We understand the importance of
                    interoperability in today’s technology ecosystem. Our
                    solutions are designed to integrate seamlessly with your
                    existing systems and applications, ensuring a cohesive and
                    efficient IT environment.
                  </p>
                </div>
              </div>
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={150}
                      className={`${
                        4 === activeIndexErp ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={60}
                      className={`${
                        4 === activeIndexErp ? "active-index" : "inactive"
                      }`}
                    />
                  </div>
                  <p
                    className={`text-justify ${
                      4 === activeIndexErp ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Real-Time Analytics and Reporting:</b>Informed
                    decision-making requires access to real-time data. Our
                    solutions provide powerful analytics and reporting tools
                    that give you instant insights into your operations, helping
                    you make data-driven decisions with confidence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className="d-flex justify-content-center position-relative"
            style={{ paddingBottom: "100px" }}
          >
            <img
              className="main-img"
              src={`${config.baseUrl}/assets/img/strategic-fuzion/vector-bg.png`}
              alt="Vector-bg"
            />
            <p className="custom-float" style={{ fontWeight: "600" }}>
              <em>Wait! There is more!</em>
            </p>
            <div className="left-arrow-4">
              <img
                src={`${config.baseUrl}/assets/img/strategic-fuzion/left-arrow-4.png`}
                alt="left-arrow"
              />
            </div>
          </div>
        </div>

        {/* All other features */}

        <div className="features-section">
          <div className="container">
            <div
              className="pd-top-10"
              style={{ width: "100%", paddingTop: "10%" }}
            >
              <h2 className="feature-content">All other features</h2>
              <div className="row g-2 px-2">
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>
                        Expertise and Experience
                      </h2>
                      <p className="text-justify">
                        With a dedicated team of experts and years of experience
                        in the industry, Fuzionest is well-equipped to deliver
                        high-quality, customized solutions that meet your
                        specific needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>
                        Client-Centric Approach
                      </h2>
                      <p className="text-justify">
                        We believe in a collaborative approach, working closely
                        with you to understand your requirements and deliver
                        solutions that exceed your expectations. Your success is
                        our priority.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>End-to-End Support</h2>
                      <p className="text-justify">
                        From initial consultation to implementation and ongoing
                        support, we provide comprehensive services to ensure the
                        smooth operation of your ERP and back office systems.
                        Our commitment to excellence extends beyond deployment.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>Proven Track Record</h2>
                      <p className="text-justify">
                        We have a proven track record of delivering successful
                        projects across various industries. Our clients trust us
                        to provide reliable, innovative, and efficient solutions
                        that drive business growth
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="footer-content-2">
            <div className="position-relative">
              <h2 className="my-4">
                Transform Your Business &nbsp;
                <span style={{ color: "#357C83" }}>with Fuzionest</span>
              </h2>
              <p>
                At Fuzionest, we are committed to helping businesses transform
                their operations through rapid customization and cutting-edge
                technology. Our accelerated ERP and back office solutions are
                designed to provide the agility, security, and efficiency you
                need to stay ahead in the competitive market.
              </p>
              <div className="decorate-2">
                <img
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-4.png`}
                  alt="handshake"
                />
              </div>
              <div className="decorate-right-2">
                <img
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-5.png`}
                  alt="handshake"
                />
              </div>
            </div>

            <Link to="/contact">
              <button className="btn btn-base border-radius-0">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default RapidCustomisationContent;
