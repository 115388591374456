import React, { useEffect, useRef } from "react";
import config from "../utils/config";

const Homepageone = () => {
  const circleRef = useRef(null);
  const heroVideoRef = useRef(null);

  useEffect(() => {
    const circle = circleRef.current;
    const heroVideo = heroVideoRef.current;

    if (circle && heroVideo) {
      // Trigger the circle reveal animation
      circle.style.animation = "reveal 1.6s ease-in-out forwards";

      // Trigger the video reveal by changing the clip-path
      requestAnimationFrame(() => {
        heroVideo.style.transition = "clip-path 1.6s ease-in-out";
        heroVideo.style.clipPath = "circle(100% at 50% 50%)";
      });

      // Listen for the end of the transition to remove the clip-path
      heroVideo.addEventListener("transitionend", () => {
        heroVideo.style.clipPath = "none";
      });

      // Clean up the event listener on unmount
      return () => {
        heroVideo.removeEventListener("transitionend", () => {
          heroVideo.style.clipPath = "none";
        });
      };
    }
  }, []);

  return (
    <>
      {/* ================== BannerOne Start ==================*/}
      <div className="hero banner-area bg-cover">
        <img
          // src="./assets/img/banner/group.png"
          src={`${config.baseUrl}/assets/img/banner/group.png`}
          alt="HeroImage"
          className="hero-image"
          ref={heroVideoRef}
        />
        {/* <video
          // src="./assets/img/landing/open-for-ai-hp-video.mp4"
          src="./assets/video/FuzionestVideo.mp4"
          alt="Hero Video"
          className="hero-image"
          ref={heroVideoRef}
          autoPlay={!window.matchMedia("(max-width: 768px)").matches}
          loop
          muted
        /> */}

        {/* <div className="hero-content">
          <h1>Redefining IT Functions</h1>
          <p>In the vision of transforming business processes through GenAI</p>
          <motion.button
            className="btn btn-base border-radius-0"
            initial={{ x: -150 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Link to="/contact">Learn More</Link>
          </motion.button>
        </div> */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            // zIndex: -111,
          }}
        />
        <div className="inner-circle" ref={circleRef}></div>
      </div>

      {/* ================== BannerOne End ==================*/}
    </>
  );
};

export default Homepageone;
