import React, { useState } from "react";
import config from "../utils/config";

const Faq = ({faqData}) => {
  const [openItems, setOpenItems] = useState({});

  const handleToggle = (index) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };


  return (
    <section className="bsb-faq-3 py-3 py-md-5 py-xl-8">
      <div className="mb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11 col-xl-10">
              <div className="d-flex align-items-end mb-5">
                <i className="bi bi-person-gear me-3 lh-1 display-5"></i>
                <h3 className="m-0 text-center">Frequently Asked Questions</h3>
              </div>
            </div>
            <div className="col-11 col-xl-10">
              <div className="accordion accordion-flush" id="faqAccount">
                {faqData.map((item, index) => (
                  <div
                    key={index}
                    className="accordion-item bg-transparent border-bottom py-3"
                  >
                    <h2
                      className="accordion-header"
                      id={`faqAccountHeading${index}`}
                      style={{ color: "#150D00" }}
                    >
                      <button
                        className="accordion-button collapsed custom-accordion-button bg-transparent fw-bold shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#faqAccountCollapse${index}`}
                        aria-expanded={openItems[index] ? "true" : "false"}
                        aria-controls={`faqAccountCollapse${index}`}
                        onClick={() => handleToggle(index)}
                      >
                        {item.question}
                        <img
                          src={
                            openItems[index]
                              ? `${config.baseUrl}/assets/img/strategic-fuzion/minus.svg`
                              : `${config.baseUrl}/assets/img/strategic-fuzion/plus.svg`
                          }
                          alt={openItems[index] ? "Minus Icon" : "Plus Icon"}
                          className="custom-icon"
                        />
                      </button>
                    </h2>
                    <div
                      id={`faqAccountCollapse${index}`}
                      className={`accordion-collapse collapse ${
                        openItems[index] ? "show" : ""
                      }`}
                      aria-labelledby={`faqAccountHeading${index}`}
                    >
                      <div className="accordion-body">
                        <p style={{ color: "#767474" }}>{item.answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
