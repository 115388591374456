import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Job1 from "../components/Job1";
import JobApplyform from "../components/jobApplyForm";

const JobApply = () => {
  return (
    <>
      <NavBar />
      <Job1 />
      <JobApplyform />
      <Footer />
    </>
  );
};

export default JobApply;
