import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import WebsiteAccessibilityHero from "../components/WebsiteAccessibilityHero";
import WebsiteAccessibilityContent from "../components/WebsiteAccessibilityContent";

const WebsiteAccessibility = () => {
  return (
    <>
      <NavBar />
      <WebsiteAccessibilityHero />
      <WebsiteAccessibilityContent />
      <Footer />
    </>
  );
};

export default WebsiteAccessibility;
