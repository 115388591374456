import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const RapidCustomisationHero = () => {
  return (
    <>
      {/* ================== BannerOne Start ==================*/}
      <div
        className="banner-area bg-relative banner-area-1 pd-bottom-100 bg-cover"
        style={{
          backgroundColor: "#FFFFFF",
          backgroundSize: "cover",
          height: "797px",
        }}
      >
        <div className="container">
          <div className="hero-content-black-1">
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              Rapid Customization - Accelerated ERP and Back Office Solutions
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.3, ease: "easeInOut" }}
            >
              In today’s fast-paced business environment, agility and efficiency
              are paramount. At Fuzionest Private Limited, we understand that
              businesses need tailored solutions that can be deployed rapidly to
              meet evolving demands. Our rapid customization services for ERP
              and back office systems are designed to deliver just
              that—providing robust, secure, and efficient solutions with
              accelerated deployment times
            </motion.p>
            <Link to="/contact">
              <motion.button
                className="btn btn-base border-radius-0"
                initial={{ x: -150 }}
                animate={{ x: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
              >
                Contact Us
              </motion.button>
            </Link>
          </div>
        </div>
      </div>
      {/* ================== BannerOne End ==================*/}
    </>
  );
};

export default RapidCustomisationHero;
