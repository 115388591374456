import React from "react";

const CookiePolicyContent = () => {
  return (
    <div>
      <h2 className="title-rsl">Fuzionest's Cookies Policy:</h2>
      <div className="rsl-pagetwo">
        
        <h3 className="rsl-heading">1. Introduction</h3>
        <p className="rsl-para">
          Fuzionest ("we", "us", "our") uses cookies and similar technologies on <b>www.fuzionest.com</b> (the "Site"). This Cookies Policy explains what cookies are, how we use them, and your choices regarding their use.
        </p>

        <h3 className="rsl-heading">2. What Are Cookies?</h3>
        <p className="rsl-para">
          Cookies are small text files placed on your device when you visit a website. They help improve your browsing experience by remembering preferences and collecting information about your interaction with the site.
        </p>

        <h3 className="rsl-heading">3. Types of Cookies We Use</h3>
        <ul>
          <li><strong>Essential Cookies:</strong> These are necessary for the website to function and cannot be disabled. They include cookies that enable you to log into secure areas of our site.</li>
          <li><strong>Performance Cookies:</strong> These cookies collect information about how visitors use our site, such as which pages are visited most often. They help us improve the website's functionality and user experience.</li>
          <li><strong>Functional Cookies:</strong> These cookies allow the website to remember choices you make (such as your username, language, or region) and provide enhanced, more personalized features.</li>
          <li><strong>Targeting Cookies:</strong> These cookies record your visit to our site, the pages you have visited, and the links you have followed. We use this information to make our website and the advertising displayed on it more relevant to your interests.</li>
        </ul>

        <h3 className="rsl-heading">4. Third-Party Cookies</h3>
        <p className="rsl-para">
          We may also use third-party cookies from services like Google Analytics to collect and report information on website usage and performance. These third-party services may use cookies to deliver ads and track performance metrics.
        </p>

        <h3 className="rsl-heading">5. Your Choices Regarding Cookies</h3>
        <p className="rsl-para">
          You have the right to accept or reject cookies. You can manage your cookie preferences through your browser settings:
        </p>
        <ul>
          <li><strong>Browser Settings:</strong> You can set your browser to block or alert you about these cookies, but some parts of the site may not work as intended if you do so.</li>
          <li><strong>Cookie Banner:</strong> Upon your first visit, you will see a cookie consent banner. You can accept all cookies or customize your preferences.</li>
        </ul>

        <h3 className="rsl-heading">6. How to Manage Cookies</h3>
        <p className="rsl-para">
          You can control and/or delete cookies as you wish. For detailed guidance, please refer to your browser's help documentation or visit <a href="https://www.aboutcookies.org" target="_blank" rel="noopener noreferrer">aboutcookies.org</a>.
        </p>

        <h3 className="rsl-heading">7. Changes to This Cookies Policy</h3>
        <p className="rsl-para">
          We may update our Cookies Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically.
        </p>

        <h3 className="rsl-heading">8. Contact Us</h3>
        <p className="rsl-para">
          If you have any questions or concerns about our use of cookies, please contact us at <a style={{ color: "#1967D2" }} href="mailto:contact@fuzionest.com">contact@fuzionest.com</a>.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicyContent;
