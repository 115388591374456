// src/config.js
const isProduction = process.env.NODE_ENV === 'production';
// const isProduction =true;

const config = {
  baseUrl: isProduction
    ? 'https://www.fuzionest.com'
    : window.location.origin,
    isProd: isProduction,
};

export default config;
