import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Job1 from "../components/Job1";
import Job2 from "../components/job2";
import { Helmet } from "react-helmet";

const Job = () => {
  const browserTabTitle =
    "Join the Fuzionest Team: Explore Career Opportunities in Tech Innovation";
  const seoTitle =
    "Join the Fuzionest Team: Explore Career Opportunities in Tech Innovation";
  const seoDescription =
    "Discover exciting career opportunities at Fuzionest. We're passionate about tech innovation and offer roles in tech consulting, project development, AI automation, and more. Explore how you can contribute to cutting-edge solutions and grow your career with us.";
  const seoKeywords =
    "Careers at fuzionest, job opportunities, tech innovation careers, tech consulting jobs, project development roles, ai automation careers, join our team, tech industry jobs, career growth, employment opportunities.";

  return (
    <>
      <Helmet>
        <title>{browserTabTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />

        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${browserTabTitle}",
              "url": "https://www.fuzionest.com/careers",
              "description": "${seoDescription}",
              "mainEntity": {
                "@type": "Organization",
                "name": "Fuzionest",
                "url": "https://www.fuzionest.com",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "contactType": "Customer Support",
                  "telephone": "+91 84387 62921",
                  "email": "contact@fuzionest.com",
                  "availableLanguage": "English"
                }
              }
            }
          `}
        </script>
        
      </Helmet>
      <NavBar />
      <Job1 />
      <Job2 />
      <Footer />
    </>
  );
};

export default Job;
