import config from "../utils/config";

let serviceList = [
  {
    title: "Customer",
    des: "Building an app like Uber with power-packed features is now easier. Enable seamless access to your service for your customers.",
    img: `${config.baseUrl}/assets/img/technology/tech-2.png`,
  },
  {
    title: "Admin and Manager",
    des: "Run marketing campaigns and manage your entire on-demand business through a single interface from Dispatch to Tracking and more.",
    img: `${config.baseUrl}/assets/img/technology/tech-3.png`,
  },
  {
    title: "Service Provider",
    des: "Enhance your fleet's productivity with route optimization feature. Give your delivery team the most powerful, and easy to use app.",
    img: `${config.baseUrl}/assets/img/technology/tech-4.png`,
  },
  {
    title: "Content Marketing",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: `${config.baseUrl}/assets/img/technology/tech-5.png`,
  },
  {
    title: "Mobile App Development",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: `${config.baseUrl}/assets/img/technology/tech-6.png`,
  },

  {
    title: "E-commerce Solutions",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: `${config.baseUrl}/assets/img/technology/tech-7.png`,
  },
];

export default serviceList;
