import React from "react";
import NavBar from "../components/NavBar";
import AboutFuzionest from "../components/AboutFuzionest";
import Homepageone from "../components/Homepageone";
import Service from "../components/services";
import News from "../components/News";
import ContactForm from "../components/contactForm";
import FooterFive from "../components/footerFive";
import { Helmet } from "react-helmet";
import ResponsibilitySection from "../components/ResponsibilitySection";
import OurServicesSection from "../components/OurServicesSection";
import CorePrincipleSection from "../components/CorePrincipleSection";

const Home = () => {
  const browserTabTitle = "Fuzionest | Making Businesses Resilient";
  const seoTitle =
    "Tech Consulting, Project Development, & Business Process Automation with Generative AI | Fuzionest";
  const seoDescription =
    "Fuzionest Private Limited provides expert tech consulting, comprehensive project development, and advanced business process automation using Generative AI. Our solutions streamline operations, boost productivity, and foster innovation for businesses worldwide. Partner with Fuzionest to leverage cutting-edge technology and achieve your business goals efficiently.";
  const seoKeywords =
    "tech consulting, automation solutions, Fuzionest, technology innovation";

  return (
    <>
      <Helmet>
        <title>{browserTabTitle}</title>
        <meta
          name="description"
          content={seoDescription}
        />
        {/* <meta name="keywords" content={seoKeywords} /> */}
        <meta
          property="og:title"
          content={seoTitle}
        />
        <meta
          property="og:description"
          content={seoDescription}
        />

        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${browserTabTitle}",
              "url": "https://www.fuzionest.com",
              "description": "${seoDescription}",
              "mainEntity": {
                "@type": "Organization",
                "name": "Fuzionest",
                "url": "https://www.fuzionest.com",
                "logo": "https://www.fuzionest.com/assets/img/landing/FuzionestLogo.svg",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "contactType": "Customer Service",
                  "telephone": "+91 84387 62921",
                  "email": "contact@fuzionest.com",
                  "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Coimbatore",
                    "addressRegion": "Tamil Nadu",
                    "postalCode": "641014",
                    "addressCountry": "India",
                    "streetAddress": "Tidel Park, Module No.12 Ground floor Elcot SEZ Coimbatore IT Park Road"
                  }
                }
              }
            }
          `}
        </script>
      </Helmet>

      <NavBar />
      <Homepageone />

      <Service />
      <ResponsibilitySection />

      <News />
      <AboutFuzionest />

      <CorePrincipleSection />
      {/* <ContactForm /> */}
      {/* <OurServicesSection /> */}
      <FooterFive />
    </>
  );
};

export default Home;
