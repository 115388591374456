import React from "react";
import "react-toastify/dist/ReactToastify.css"; // Import the styles

function AddressSection() {
  return (
    <div className="address-container">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h3 style={{ color: "#202021" }}>
              We are located at various places
            </h3>
          </div>
          <div className="col-md-4">
            <h6 style={{ color: "#202021" }}>Address 1</h6>
            <p>
              Tidel Park, Module No.12 Ground floor Elcot SEZ Coimbatore IT Park
              Road, Coimbatore, Tamil Nadu 641014.
            </p>
          </div>
          <div className="col-md-4">
            <h6 style={{ color: "#202021" }}>Address 2</h6>
            <p>
              No. 11/4, Pooja Garden, Kalapatti Main Rd, Civil Aerodrome
              Post,Coimbatore, Tamil Nadu 641014.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressSection;
