import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const SmartAutomationHero = () => {
  return (
    <>
      {/* ================== BannerOne Start ==================*/}
      <div
        className="banner-area bg-relative banner-area-1 pd-bottom-100 bg-cover"
        style={{
          backgroundColor: "#FFFFFF",
          backgroundSize: "cover",
          height: "797px",
        }}
      >
        <div className="container">
          <div className="hero-content-black-1">
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              Smart Automation: Boosting Workforce Efficiency with GEN-AI
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.3, ease: "easeInOut" }}
            >
              In the dynamic world of business, efficiency and productivity are
              crucial for maintaining a competitive edge. At Fuzionest Private
              Limited, we believe that the key to unlocking unprecedented levels
              of efficiency lies in smart automation powered by Generative AI
              (GenAI). By integrating GenAI into your business processes, you
              can transform operations and significantly enhance workforce
              productivity.
            </motion.p>
            <Link to="/contact">
              <motion.button
                className="btn btn-base border-radius-0"
                initial={{ x: -150 }}
                animate={{ x: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
              >
                Contact Us
              </motion.button>
            </Link>
          </div>
        </div>
      </div>
      {/* ================== BannerOne End ==================*/}
    </>
  );
};

export default SmartAutomationHero;
