import React from 'react'

const ContactArea2 = () => {
  return (
    <div>
        <div className='contact-g-map '  id="ContactDetails" style={{ marginBottom: '15px' }}>
       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.092759621636!2d77.01649867466185!3d11.031667054434687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba858dcc260774d%3A0x8a1b573dae849aa1!2sFuzionest%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1696325941974!5m2!1sen!2sin" width="600" height="450" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
       </div>

    </div>
  )
}

export default ContactArea2