import React from "react";
import { Link } from "react-router-dom";
import config from "../utils/config";

const BannerOne = () => {
  return (
    <>
      {/* ================== BannerOne Start ==================*/}

      <div
        className="banner-area bg-relative banner-area-1 pd-bottom-100 bg-cover"
        style={{
          backgroundImage: `url(${`${config.baseUrl}/assets/img/banner/0.png`})`,
        }}
      >
        <div
          className="banner-area-home-page  banner-area-1 pd-bottom-100 bg-cover"
          style={{
            backgroundImage: `url(${`${config.baseUrl}/assets/img/banner/gray.png`})`,
          }}
        >
          <img
            className="animate-imgggg"
            src={`${config.baseUrl}/assets/img/technology/world.png`}
            alt="Fuzionest World"
          />

          <img
            className="animate-imggg "
            src={`${config.baseUrl}/assets/img/technology/tech-1.png`}
            alt="Fuzionest Technology"
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="banner-inner pe-xl-5">
                  <h2
                    className="title bannerone-title"
                    data-aos="fade-right"
                    data-aos-delay="200"
                    data-aos-duration="1500"
                    style={{ color: "white" }}
                  >
                    Looking to Create an App Like Uber Eats? We can Power It.
                  </h2>
                  <p
                    className="content banner-content pe-xl-5"
                    data-aos="fade-right"
                    data-aos-delay="250"
                    data-aos-duration="1500"
                    style={{ color: "white", fontweight: "lighter" }}
                  >
                    Get Fully Customised White Labeled
                    <br />
                    Uber Like Apps for Your On-Demand Business
                  </p>

                  <div
                    className="single-input-inner"
                    style={{ marginTop: "-1%" }}
                  >
                    <input
                      data-aos="fade-right"
                      data-aos-delay="500"
                      data-aos-duration="1500"
                      name="mail"
                      type="email" // Change type to 'email'
                      id="inputtext"
                      placeholder="Enter your email address"
                      required
                    />

                    <Link
                      className="btn btn-border-base16"
                      data-aos-delay="500"
                      data-aos-duration="1500"
                      to="/contact#ContactDetails"
                      style={{ marginLeft: "10%" }}
                    >
                      Book A Demo
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-8 offset-xl-2 offset-lg-1">
                <div
                  className="banner-thumb mt-5 mt-lg-0 ms-4 ms-lg-0"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerOne End ==================*/}
    </>
  );
};

export default BannerOne;
