import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import config from "../utils/config";

const GridExample = () => {
  return (
    <div className="containerservice">
      <div className="aboutpage4-heading" style={{ padding: "0 10%" }}>
        <h2
          className="content2-aboutpage4"
          style={{ marginTop: "7%", fontWeight: "700" }}
        >
          The People Behind <span style={{ color: "#202021 " }}>Fuzionest</span>
        </h2>
        <p className="contentpara5-aboutpage4" style={{ paddingTop: "30px" }}>
          Meet the talented team of designers, marketers, developers, and
          customer service reps that make Fuzionest possible.
        </p>
      </div>
      <div
        className="row justify-content-center gx-4"
        style={{ marginTop: "60px" }}
      >
        <div className="col-md-3 mb-4" style={{ margin: "0 1%" }}>
          <div className="row">
            <div className="column-1">
              <img
                className="animate-img-aboutpage4"
                // src="assets/img/about/team/Nagaraj.jpg"
                src={`${config.baseUrl}/assets/img/about/team/Nagaraj.jpg`}
                alt="CEO"
                style={{ borderRadius: "5%" }}
              />
            </div>
          </div>

          <h6
            style={{
              fontWeight: "bolder",
              fontSize: "16px",
              marginBottom: "20px",
              marginTop: "15%",
              textAlign: "center",
            }}
          >
            NAGARAJAN SUBRAMANIAN
          </h6>
          <p style={{ textAlign: "center" }}>
            Director & Chief Executive Officer
          </p>
          <p className="aboutpage4-p" style={{ width: "100%" }}>
            Nagarajan, our CEO, is a visionary leader with a passion for
            innovation. With over a decade in the software industry, Nagarajan
            has steered multiple projects to success, always keeping an eye on
            the horizon for the next technological advancement. A believer in
            cultivating strong relationships, he prioritizes collaboration and
            believes that the best solutions arise when diverse minds come
            together.
          </p>
        </div>
        <div className="col-md-3 mb-4" style={{ margin: "0 1%" }}>
          <div className="row">
            <div className="column-1">
              <img
                className="animate-img-aboutpage4"
                // src="assets/img/about/team/Mounesh.jpg"
                src={`${config.baseUrl}/assets/img/about/team/Mounesh.jpg`}
                alt="CFO"
                style={{ borderRadius: "5%" }}
              />
            </div>
          </div>

          <h6
            style={{
              fontWeight: "bolder",
              fontSize: "16px",
              marginBottom: "20px",
              marginTop: "15%",
              textAlign: "center",
            }}
          >
            MOUNESHWAR KALAIKUMAR
          </h6>
          <p style={{ textAlign: "center" }}>
            Director & Chief Financial Officer
          </p>
          <p className="aboutpage4-p" style={{ width: "100%" }}>
            Mouneshwar, our CFO, is the strategic mind behind our company's
            financial health and growth. With a background in data analytics and
            a deep understanding of the software industry's financial landscape,
            Mouneshwar has been pivotal in ensuring our company remains both
            profitable and sustainable. Outside the boardroom, Mouneshwar is an
            avid drone-tech enthusiast and also pursuing his research on
            algorithmic trading.
          </p>
        </div>
        <div className="col-md-3 mb-4" style={{ margin: "0 1%" }}>
          <div className="row">
            <div className="column-1">
              <img
                className="animate-img-aboutpage4"
                // src="assets/img/about/team/Tamilarasu.jpg"
                src={`${config.baseUrl}/assets/img/about/team/Tamilarasu.jpg`}
                alt="Director & CTO"
                style={{ borderRadius: "5%" }}
              />
            </div>
          </div>

          <h6
            style={{
              fontWeight: "bolder",
              fontSize: "16px",
              marginBottom: "20px",
              marginTop: "15%",
              textAlign: "center",
            }}
          >
            TAMILARASU MUTHUSAMY
          </h6>
          <p style={{ textAlign: "center" }}>
            Director & Chief Technology Officer
          </p>
          <p className="aboutpage4-p" style={{ width: "100%" }}>
            Tamil, our CTO, is the driving force behind our company's
            technological vision and innovation. With an innate ability to
            anticipate industry trends and a relentless pursuit of cutting-edge
            solutions, Tamil has positioned our company at the forefront of
            software evolution. With over a decade in the tech arena, his
            expertise spans from deep coding knowledge to architecting scalable
            infrastructures. Tamil is not only a technologist but also a mentor
            for a number of budding talents.
          </p>
        </div>
      </div>
      <div className="row justify-content-center gx-4">
        <div className="col-md-3 mb-4" style={{ margin: "0 1%" }}>
          <div className="row">
            <div className="column-1">
              <img
                className="animate-img-aboutpage4"
                // src="assets/img/about/team/Shakthivel.jpg"
                src={`${config.baseUrl}/assets/img/about/team/Shakthivel.jpg`}
                alt="HR Manager"
                style={{ borderRadius: "5%" }}
              />
            </div>
          </div>

          <h6
            style={{
              fontWeight: "bolder",
              fontSize: "20px",
              marginBottom: "20px",
              marginTop: "15%",
              textAlign: "center",
            }}
          >
            SAKTHIVEL KRISHNAMOORTHI
          </h6>
          <p style={{ textAlign: "center" }}>HR Manager</p>
          <p className="aboutpage4-p">
            Sakthi, our Human Resources Manager, is the heart and soul behind
            our company's thriving work culture. With a unique blend of empathy
            and strategic insight, Sakthi has cultivated an environment where
            talent flourishes and collaboration is second nature. Over his
            10-year tenure in the HR domain, he has been instrumental in
            attracting, developing, and retaining top-tier talent, ensuring that
            our team is always equipped with the best and brightest minds.
          </p>
        </div>
        <div className="col-md-3 mb-4" style={{ margin: "0 1%" }}>
          <div className="row">
            <div className="column-1">
              <img
                className="-img-aboutpage4"
                // src="assets/img/about/team/Prasanth.jpg"
                src={`${config.baseUrl}/assets/img/about/team/Prasanth.jpg`}
                alt="Project Manager"
                style={{ borderRadius: "5%" }}
              />
            </div>
            <div className="column"></div>
          </div>

          <h6
            style={{
              fontWeight: "bolder",
              fontSize: "20px",
              marginBottom: "20px",
              marginTop: "15%",
              textAlign: "center",
            }}
          >
            PRASANTH MAHALINGAM
          </h6>
          <p style={{ textAlign: "center" }}>Project Manager</p>
          <p className="aboutpage4-p">
            Prasanth, our esteemed Project Manager, is the linchpin that holds
            our projects and teams together. With a knack for detail-oriented
            planning and a commitment to delivering on promises, Prasanth
            ensures that every project we undertake is executed to perfection.
            With over 12 years of experience in software development and project
            management, he has a proven track record of guiding complex projects
            from inception to completion, consistently meeting both deadlines
            and quality benchmarks.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GridExample;
