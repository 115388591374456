import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import countryList from "../../utils/country.json";

const ContactForm = () => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    country: Yup.string().required("Country is required"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number is not valid")
      .required("Phone number is required"),
    address: Yup.string().required("Address is required"),
    message: Yup.string().required("Message is required"),
  });

  // Initialize React Hook Form
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setIsButtonLoading(true);
    try {
      const response = await fetch("https://api.fuzionest.com/enquiry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Request was successful, display a success toast
        toast.success("Form submitted successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        // Handle errors here.
        toast.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message ? error.message : "Server Error!");
    } finally {
      setIsButtonLoading(false);
    }
  };

  return (
    <div className="form-container" style={{ background: "#202020" }}>
      <form
        className="contact-form contact-style"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <h2
            style={{
              marginTop: "20px",
              fontWeight: "bold",
              marginBottom: "5%",
              color: "#fff",
              textAlign: "center",
            }}
          >
            Contact Us
          </h2>
        </div>
        <div className="mb-4 row align-items-center">
          <label className="form-label col-md-4 text-md-right" htmlFor="name">
            Name
          </label>
          <div className="col-md-8">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  id="name"
                  className="form-control input-style"
                  placeholder="Enter your name"
                />
              )}
            />
            <p className="text-danger">{errors.name?.message}</p>
          </div>
        </div>
        <div className="mb-4 row align-items-center">
          <label
            className="form-label col-md-4 text-md-right"
            htmlFor="country"
          >
            Country
          </label>
          <div className="col-md-8">
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <select
                  {...field}
                  id="country"
                  className="form-control input-style"
                >
                  <option value="">Select your country</option>
                  {countryList.map((country) => (
                    <option key={country.name} value={country.value}>
                      {country.name.toUpperCase()} ({country.value})
                    </option>
                  ))}
                </select>
              )}
            />
            <p className="text-danger">{errors.country?.message}</p>
          </div>
        </div>
        <div className="mb-4 row align-items-center">
          <label className="form-label col-md-4 text-md-right" htmlFor="phone">
            Phone
          </label>
          <div className="col-md-8">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  id="phone"
                  className="form-control input-style"
                  placeholder="Enter your phone number"
                />
              )}
            />
            <p className="text-danger">{errors.phone?.message}</p>
          </div>
        </div>
        <div className="mb-4 row align-items-center">
          <label
            className="form-label col-md-4 text-md-right"
            htmlFor="address"
          >
            Address
          </label>
          <div className="col-md-8">
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  id="address"
                  className="form-control input-style"
                  placeholder="Enter your address"
                />
              )}
            />
            <p className="text-danger">{errors?.address?.message}</p>
          </div>
        </div>
        <div className="mb-4 row align-items-center">
          <label
            className="form-label col-md-4 text-md-right"
            htmlFor="message"
          >
            How can we help you?
          </label>
          <div className="col-md-8">
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  id="message"
                  className="form-control input-style"
                  placeholder="Enter here"
                  rows="5"
                />
              )}
            />
            <p className="text-danger">{errors.message?.message}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <ToastContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 offset-md-4">
            {isButtonLoading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-primary mb-4 border-radius-0"
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
