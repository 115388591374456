import React, { useEffect, useState } from "react";
import "./Job2.css";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Jobs from "../utils/jobs.json";
import AddressSection from "./AddressSection";

function JobApplyform() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobId = parseInt(searchParams.get("jobId"));
  const [jobDatas, setJobDatas] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (jobId) {
      // console.log(Jobs);
      const jobData = Jobs.find((data) => data.id === jobId);
      // console.log("jobData:", jobData);
      setJobDatas(jobData);
    }
  }, []);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number is not valid")
      .required("Phone number is required"),
    degree: Yup.string().required("Degree is required"),
    specialization: Yup.string().required("Specialization is required"),
    yearOfGraduation: Yup.string().required("Address is required"),
    currentLocation: Yup.string().required("Current Location is required"),
    gender: Yup.string().required("Gender is required"),
    resume: Yup.mixed().required("Resume is required"),
  });

  // Initialize React Hook Form
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setIsButtonLoading(true);

    try {
      const formData = new FormData();
      formData.append("jobId", jobDatas.id);
      formData.append("role", jobDatas.title);
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("degree", data.degree);
      formData.append("specialization", data.specialization);
      formData.append("yearOfGraduation", data.yearOfGraduation);
      formData.append("currentLocation", data.currentLocation);
      formData.append("coverLetter", data.coverLetter ? data.coverLetter : "");
      formData.append("gender", data.gender);
      formData.append("resume", selectedFile);

      // console.log("resume:", selectedFile);
      // console.log("formData:", formData);
      console.log("FormData:", data);
      console.log("FormData contents:");
      for (const [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
      const response = await fetch("https://api.fuzionest.com/jobApply", {
      // const response = await fetch("http://192.168.1.25:3000/jobApply", {
        method: "POST",
        body: formData,
      });

      const result = await response.json(); // Assuming the response is in JSON format

      if (response.status === 200) {
        if (result.status === 1) {
          toast.success("Applied successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else if (result.status === -1) {
          toast.error(result.message || "Submission failed");
        }
      }
    } catch (error) {
      // console.log("Error:", error);
      toast.error(error.message ? error.message : "Server Error!");
    } finally {
      setIsButtonLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  return (
    <div className="job2" style={{ background: "#202020" }}>
      <div
        className="title container"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "20px 0",
        }}
      >
        {/* <p style={{ color: "#fff", textAlign: "center", margin: "20px 0" }}>
          Fuzionest | Making Businesses Resilient
        </p> */}
        <h2 className="title-job2 text-white ">{jobDatas?.title}</h2>
        <h4 className="text-white job-description mt-2">Job Description:</h4>

        {jobDatas?.role?.map((section, index) => (
          <div key={index} className="job-description">
            <p
              className="text-white"
              style={{ fontWeight: "300", fontSize: "20px" }}
            >
              &#x2022; {section.content}
            </p>
          </div>
        ))}
        <p className="text-white">
          Please note that we have requirements for this role in Coimbatore.
        </p>
      </div>
      <div className="form-container" style={{ background: "#202020" }}>
        <form
          className="contact-form contact-style"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <h2
              style={{
                marginTop: "20px",
                fontWeight: "bold",
                marginBottom: "5%",
                color: "#fff",
                textAlign: "center",
              }}
            >
              Apply Here
            </h2>
          </div>
          <div className="mb-4 row align-items-center">
            <label className="form-label col-md-4 text-md-right" htmlFor="name">
              Full Name <span className="text-danger">*</span>
            </label>
            <div className="col-md-8">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    id="name"
                    className="form-control input-style"
                    placeholder="Enter your name"
                  />
                )}
              />
              <p className="text-danger">{errors.name?.message}</p>
            </div>
          </div>

          <div className="mb-4 row align-items-center">
            <label
              className="form-label col-md-4 text-md-right"
              htmlFor="email"
            >
              Email <span className="text-danger">*</span>
            </label>
            <div className="col-md-8">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="email"
                    id="email"
                    className="form-control input-style"
                    placeholder="Enter your email"
                  />
                )}
              />
              <p className="text-danger">{errors.email?.message}</p>
            </div>
          </div>
          <div className="mb-4 row align-items-center">
            <label
              className="form-label col-md-4 text-md-right"
              htmlFor="phone"
            >
              Phone <span className="text-danger">*</span>
            </label>
            <div className="col-md-8">
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    id="phone"
                    className="form-control input-style"
                    placeholder="Enter your phone number"
                  />
                )}
              />
              <p className="text-danger">{errors.phone?.message}</p>
            </div>
          </div>

          <div className="mb-4 row align-items-center">
            <label
              className="form-label col-md-4 text-md-right"
              htmlFor="degree"
            >
              Degree <span className="text-danger">*</span>
            </label>
            <div className="col-md-8">
              <Controller
                name="degree"
                control={control}
                render={({ field }) => (
                  <select
                    {...field}
                    id="degree"
                    className="form-control input-style"
                  >
                    <option value="">Select Degree</option>
                    <option value="B.E">B.E</option>
                    <option value="B.Tech">B.Tech</option>
                    <option value="B.Sc">B.Sc</option>
                    <option value="B.A">B.A</option>
                    <option value="B.Com">B.Com</option>
                    <option value="BBA">BBA</option>
                    <option value="BCA">BCA</option>
                    <option value="M.E">M.E</option>
                    <option value="M.Tech">M.Tech</option>
                    <option value="M.Sc">M.Sc</option>
                    <option value="M.A">M.A</option>
                    <option value="M.Com">M.Com</option>
                    <option value="MBA">MBA</option>
                    <option value="MCA">MCA</option>
                  </select>
                )}
              />
              <p className="text-danger">{errors?.degree?.message}</p>
            </div>
          </div>

          <div className="mb-4 row align-items-center">
            <label
              className="form-label col-md-4 text-md-right"
              htmlFor="specialization"
            >
              Specialization <span className="text-danger">*</span>
            </label>
            <div className="col-md-8">
              <Controller
                name="specialization"
                control={control}
                render={({ field }) => (
                  <select
                    {...field}
                    id="specialization"
                    className="form-control input-style mt-2"
                  >
                    <option value="">Select Specialization</option>
                    <option value="Information Technology">
                      Information Technology
                    </option>
                    <option value="Computer Science">Computer Science</option>
                    <option value="Electrical Engineering">
                      Electrical Engineering
                    </option>
                    <option value="Mechanical Engineering">
                      Mechanical Engineering
                    </option>
                    <option value="Civil Engineering">Civil Engineering</option>
                    <option value="Electronics and Communication">
                      Electronics and Communication
                    </option>
                    <option value="Biotechnology">Biotechnology</option>
                    <option value="Chemical Engineering">
                      Chemical Engineering
                    </option>
                    <option value="Physics">Physics</option>
                    <option value="Mathematics">Mathematics</option>
                    <option value="Chemistry">Chemistry</option>
                    <option value="English">English</option>
                    <option value="Economics">Economics</option>
                    <option value="Accounting">Accounting</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Finance">Finance</option>
                    <option value="Human Resources">Human Resources</option>
                    <option value="Operations Management">
                      Operations Management
                    </option>
                    <option value="Data Science">Data Science</option>
                    <option value="Artificial Intelligence">
                      Artificial Intelligence
                    </option>
                    <option value="Cybersecurity">Cybersecurity</option>
                  </select>
                )}
              />
              <p className="text-danger">{errors?.specialization?.message}</p>
            </div>
          </div>

          <div className="mb-4 row align-items-center">
            <label
              className="form-label col-md-4 text-md-right"
              htmlFor="yearOfGraduation"
            >
              Year of Graduation <span className="text-danger">*</span>
            </label>
            <div className="col-md-8">
              <Controller
                name="yearOfGraduation"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    id="yearOfGraduation"
                    className="form-control input-style"
                    placeholder="Enter your yearOfGraduation"
                  />
                )}
              />
              <p className="text-danger">{errors?.yearOfGraduation?.message}</p>
            </div>
          </div>

          <div className="mb-4 row align-items-center">
            <label
              className="form-label col-md-4 text-md-right"
              htmlFor="currentLocation"
            >
              Current Location <span className="text-danger">*</span>
            </label>
            <div className="col-md-8">
              <Controller
                name="currentLocation"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    id="currentLocation"
                    className="form-control input-style"
                    placeholder="Enter your Current Location"
                  />
                )}
              />
              <p className="text-danger">{errors?.currentLocation?.message}</p>
            </div>
          </div>

          <div className="mb-4 row align-items-center">
            <label
              className="form-label col-md-4 text-md-right"
              htmlFor="gender"
            >
              Gender <span className="text-danger">*</span>
            </label>
            <div className="col-md-8">
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <select
                    {...field}
                    id="gender"
                    className="form-control input-style"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="female">others</option>
                    <option value="female">prefer not to say</option>
                  </select>
                )}
              />
              <p className="text-danger">{errors.gender?.message}</p>
            </div>
          </div>

          <div className="mb-4 row align-items-center">
            <label
              className="form-label col-md-4 text-md-right"
              htmlFor="resume"
            >
              Resume <span className="text-danger">*</span>
            </label>
            <div className="col-md-8">
              <Controller
                name="resume"
                control={control}
                render={({ field }) => {
                  // console.log("field:", field);
                  return (
                    <input
                      // {...field}
                      type="file"
                      id="resume"
                      onChange={(e) => {
                        handleFileChange(e);
                        field.onChange(
                          e.target.files[0]?.name
                            ? e.target.files[0].name
                            : null
                        );
                      }}
                      className="form-control input-style"
                      accept=".pdf,.doc,.docx"
                    />
                  );
                }}
              />
              <p className="text-danger">{errors.resume?.message}</p>
            </div>
          </div>

          <div className="mb-4 row align-items-center">
            <label
              className="form-label col-md-4 text-md-right"
              htmlFor="coverLetter"
            >
              Cover Letter
            </label>
            <div className="col-md-8">
              <Controller
                name="coverLetter"
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    id="coverLetter"
                    className="form-control input-style"
                    placeholder="Enter here"
                    rows="5"
                  />
                )}
              />
              <p className="text-danger">{errors.coverLetter?.message}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ToastContainer />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 offset-md-4">
              {isButtonLoading ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary mb-4 border-radius-0"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <AddressSection />
    </div>
  );
}

export default JobApplyform;
