import React from "react";

const ImageGrid = () => {
  return (
    <div className="grid-heading">
      <h4
        className="grid-head-work"
        style={{ fontWeight: "700", fontFamily: "Inter, sans-serif" }}
      >
        Work Culture
      </h4>
      <p className="gridhead-work" style={{ color: "black", maxWidth: "100%" }}>
        Discover a work culture that's more than just a routine – it's a vibrant
        ecosystem of innovation and collaboration. At Fuzionest, we foster an
        environment where creativity thrives, challenges are embraced, and every
        voice is valued. Our culture celebrates diversity, encourages continuous
        learning, and empowers each team member to push boundaries and explore
        new horizons. With a perfect blend of camaraderie and autonomy, we're
        not just shaping code – we're shaping a community that nurtures growth
        and brings extraordinary ideas to life. Welcome to a workplace where
        passion meets technology, and together, we redefine possibilities.
      </p>
    </div>
  );
};

export default ImageGrid;
