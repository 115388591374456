import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import DecisionMakingBlogContent from "../components/DecisionMakingBlogContent";
import DecisionMakingBlog from "../components/DecisionMakingBlog";

const DecisionMakingBlogPage = () => {
  return (
    <>
      <NavBar />
      <DecisionMakingBlog />
      <DecisionMakingBlogContent />
      {/* <Rslpagethree /> */}
      <Footer />
    </>
  );
};

export default DecisionMakingBlogPage;
