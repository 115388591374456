import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import WebsiteTermsOfUseHero from "../components/WebsiteTermsOfUseHero";
import WebsiteTermsOfUseContent from "../components/WebsiteTermsOfUseContent";

const WebsiteTermsOfUse = () => {
  return (
    <>
      <NavBar />
      <WebsiteTermsOfUseHero />
      <WebsiteTermsOfUseContent />
      <Footer />
    </>
  );
};

export default WebsiteTermsOfUse;
