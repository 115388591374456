import React from "react";

const WebsiteAccessibilityContent = () => {
  return (
    <div>
      <h2 className="title-rsl">Fuzionest's Accessibility Statement:</h2>
      <div className="rsl-pagetwo">
        <h3 className="rsl-heading">1. Commitment to Accessibility</h3>
        <p className="rsl-para">
          Fuzionest is committed to ensuring digital accessibility for all users, including those with disabilities. We strive to continually improve the user experience for everyone and apply relevant accessibility standards.
        </p>

        <h3 className="rsl-heading">2. Measures to Support Accessibility</h3>
        <ul>
          <li>Integrate accessibility into our mission and policies.</li>
          <li>Incorporate accessibility into our design and development processes.</li>
          <li>Provide accessibility training to our staff.</li>
        </ul>

        <h3 className="rsl-heading">3. Conformance Status</h3>
        <p className="rsl-para">
          We aim to comply with the Web Content Accessibility Guidelines (WCAG) 2.1 Level AA.
        </p>

        <h3 className="rsl-heading">4. Feedback</h3>
        <p className="rsl-para">
          We welcome your feedback on the accessibility of Fuzionest. Please let us know if you encounter any barriers:
        </p>
        <ul>
          <li>Email: <a style={{ color: "#1967D2" }} href="mailto:contact@fuzionest.com">contact@fuzionest.com</a></li>
          <li>Phone: +91 84387 62921</li>
        </ul>

        <h3 className="rsl-heading">5. Technical Specifications</h3>
        <p className="rsl-para">
          Our website relies on the following technologies for accessibility:
        </p>
        <ul>
          <li>HTML</li>
          <li>WAI-ARIA</li>
          <li>CSS</li>
          <li>JavaScript</li>
        </ul>

        <h3 className="rsl-heading">6. Assessment Approach</h3>
        <p className="rsl-para">
          We assess the accessibility of Fuzionest through self-evaluation and periodic third-party assessments.
        </p>

        <h3 className="rsl-heading">7. Compatibility</h3>
        <p className="rsl-para">
          Our website is designed to be compatible with major web browsers and assistive technologies.
        </p>

        <h3 className="rsl-heading">8. Limitations</h3>
        <p className="rsl-para">
          Despite our best efforts, there may be some limitations. We will address these issues as they are identified.
        </p>

        <h3 className="rsl-heading">9. Contact Us</h3>
        <p className="rsl-para">
          For further assistance or to report an accessibility issue, please contact us at <a style={{ color: "#1967D2" }} href="mailto:contact@fuzionest.com">contact@fuzionest.com</a>
        </p>
      </div>
    </div>
  );
};

export default WebsiteAccessibilityContent;
