import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../utils/config";

const FaqAreaOne = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div
        className="faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110"
      >
        <div className="container">
          <div
            className="row pd-top-10"
            style={{ width: "100%", paddingLeft: "8%" }}
          >
            <div
              className="col-xl-5 col-lg-6 col-md-8 order-lg-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner pt-lg-3">
                <img
                  className="main-img"
                  // src="assets/img/about/1/1.png"
                  src={`${config.baseUrl}/assets/img/about/1/1.png`}
                  alt="Live Tracking"
                  style={{ paddingTop: "5%", alignItems: "flex-end" }}
                />
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-6"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0">
                <h2
                  className="title"
                  style={{ marginTop: "10%", fontWeight: "800" }}
                >
                  Live Tracking for Your Customers
                </h2>
                <p className="content">
                  Transparency enhances the overall customer experience by
                  reducing uncertainty and providing accurate arrival time
                  estimates.
                </p>
              </div>
              <div className="accordion accordion-inner " id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <div className="accordin-button">
                      <div className="grid-container-aboutareaone faqareaone-grid">
                        <div className="grid-check-aboutareaone faqarea">
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Transparency</span>
                          </div>
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Trust and Reliability</span>
                          </div>
                        </div>

                        <div className="grid-check-aboutareaone grid2-about">
                          {/* style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}> */}
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Accurate Expectations</span>
                          </div>
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Competitive Edge</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </h2>
                  <p
                    className="content"
                    style={{ marginTop: "5%", width: "100%" }}
                  >
                    Live tracking in a food delivery application is crucial
                    because it offers customers transparency, convenience, and
                    peace of mind throughout the delivery process. It allows
                    customers to monitor the real-time progress of their orders,
                    from the moment it's prepared to the point of delivery.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row " style={{ width: "100%", paddingLeft: "8%" }}>
            <div
              className="col-xl-5 col-lg-6 col-md-8"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner pt-lg-3">
                <img
                  className="main-img"
                  // src="assets/img/about/1/2.png"
                  src={`${config.baseUrl}/assets/img/about/1/2.png`}
                  alt="Cart UI"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                />
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-6"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0">
                <h2
                  className="title"
                  style={{ marginTop: "4%", fontWeight: "800" }}
                >
                  Customisable menu option for your customers
                </h2>
                <p className="content" style={{ width: "100%" }}>
                  Customizable menu feature within a food delivery app empowers
                  customers to tailor their orders according to their
                  preferences, dietary requirements, and cravings.{" "}
                </p>
              </div>
              <div className="accordion accordion-inner " id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <div className="accordin-button">
                      <div className="grid-container-aboutareaone">
                        <div className="grid-check-aboutareaone">
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Personalization</span>
                          </div>
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Enhanced Engagement</span>
                          </div>
                        </div>

                        <div className="grid-check-aboutareaone grid2-about">
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Flexibility</span>
                          </div>
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Increased Satisfaction</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </h2>
                  <div>
                    <p
                      className="content"
                      style={{ marginTop: "5%", width: "100%" }}
                    >
                      This feature not only enhances the customer experience but
                      also promotes engagement and loyalty. Incorporating a
                      customizable menu feature into a food delivery app not
                      only adds value to the user experience but also positions
                      the app as a versatile and customer-centric platform that
                      caters to a wide array of tastes and needs.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row " style={{ width: "100%", paddingLeft: "8%" }}>
            <div
              className="col-xl-5 col-lg-6 col-md-8 order-lg-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner pt-lg-3">
                <img
                  className="main-img"
                  // src="assets/img/about/1/3.png"
                  src={`${config.baseUrl}/assets/img/about/1/3.png`}
                  alt="Payment UI"
                />
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-6"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0">
                <h2 className="title" style={{ fontWeight: "800" }}>
                  Wallet feature for your customers
                </h2>
                <p className="content">
                  A wallet feature integrated into a food delivery app offers
                  users a convenient and secure method to manage their funds for
                  orders and transactions. It functions as a digital wallet
                  within the app, allowing users to store money, make payments,
                  and enjoy various benefits.
                </p>
              </div>
              <div className="accordion accordion-inner " id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <div className="accordin-button">
                      <div className="grid-container-aboutareaone">
                        <div className="grid-check-aboutareaone">
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Effortless Transactions</span>
                          </div>
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Promotions and Loyalty</span>
                          </div>
                        </div>

                        <div className="grid-check-aboutareaone grid2-about">
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Speed and Convenience</span>
                          </div>
                          <div className="grid-item">
                            <FaCheckCircle style={{ color: "#202021 " }} />
                            <span>Faster Checkout</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </h2>
                  <p
                    className="content"
                    style={{ marginTop: "5%", width: "100%" }}
                  >
                    Incorporating a wallet feature into a food delivery app
                    enhances user experience by providing a streamlined, secure,
                    and flexible payment method. It encourages loyalty, repeat
                    orders, and overall engagement while aligning with the
                    convenience-driven nature of modern digital services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Link
            className="btn btn-border-base18"
            data-aos-delay="500"
            data-aos-duration="1500"
            to="/contact#ContactDetails"
          >
            Book A Demo
          </Link>
        </div>
      </div>
      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default FaqAreaOne;
