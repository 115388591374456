import React from "react";
import config from "../utils/config";

const Job = () => {
  return (
    <>
      {/* ================== BannerOne Start ==================*/}

      <div
        className="banner-area bg-relative banner-area-1 pd-bottom-100 bg-cover"
        // style={{ backgroundImage: 'url("./assets/img/banner/image.png")' }}
        style={{
          backgroundImage: `url(${`${config.baseUrl}/assets/img/banner/image.png`})`,
        }}
      >
        <div
          className="banner-area-home-page  banner-area-1 pd-bottom-100 bg-cover"
          // style={{ backgroundImage: 'url("./assets/img/banner/gray.png")' }}
          style={{
            backgroundImage: `url(${`${config.baseUrl}/assets/img/banner/gray.png`})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="about-content">
                  <h2>We’re Looking For Skilled People</h2>
                  <p
                    data-aos="fade-right"
                    data-aos-delay="250"
                    data-aos-duration="1500"
                    style={{
                      color: "white",
                      fontweight: "lighter",
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    We're your strategic partner in harnessing the power of
                    technology to drive innovation, efficiency, and growth. With
                    a passion for creating exceptional solutions, we're
                    dedicated to helping businesses of all sizes navigate the
                    ever-evolving digital landscape.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-8 offset-xl-2 offset-lg-1">
                <div
                  className="banner-thumb mt-5 mt-lg-0 ms-4 ms-lg-0"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerOne End ==================*/}
    </>
  );
};

export default Job;
