import React from "react";
import { Link } from "react-router-dom";
import config from "../utils/config";

const Blogpagefour = () => {
  return (
    <div className="container">
      <h3 style={{ textAlign: "center", marginTop: "5%" }}>
        Discover the latest posts
      </h3>

      <div className="row" style={{ padding: "0 5%" }}>
        <h4
          id="inside-blog4"
          style={{
            borderBottom: "2px solid #e9edec",
            marginBottom: "3%",
            paddingBottom: "1%",
          }}
        >
          Inside Fuzionest
        </h4>

        <div className="col-md-4 " style={{ border: "none" }}>
          <Link to="/cloud-blog">
            <div className="blogpage4-grid" style={{ border: "none" }}>
              <img
                // src="/assets/img/blog/AWS.png"
                src={`${config.baseUrl}/assets/img/blog/AWS.png`}
                alt="Optimizing GlobalApplication Performance"
                style={{ borderRadius: "3px" }}
              />

              <h2 className="blogpage4-h6" style={{ marginTop: "5%" }}>
                Optimizing Global Application Performance: A Guide to
                Region-Based Routing with AWS
              </h2>
              <p>
                Learn how to enhance your application's speed and reliability by
                implementing region-based routing using AWS's global
                infrastructure.
              </p>
            </div>
          </Link>
        </div>
        <div className="col-md-4" style={{ border: "none" }}>
          <Link to="/flutter-blog">
            <div className="blogpage4-grid" style={{ border: "none" }}>
              <img
                // src="assets/img/blog/flutter.png"
                src={`${config.baseUrl}/assets/img/blog/flutter.png`}
                alt="Flutter"
                style={{ borderRadius: "3px" }}
              />

              <h2 className="blogpage4-h6" style={{ marginTop: "5%" }}>
                Exploring the Power of Flutter Packages and Isolates
              </h2>
              <p>
                Discover how Flutter packages and isolates can be leveraged to
                build efficient, high-performance mobile applications.
              </p>
            </div>
          </Link>
        </div>

        <div className="col-md-4 " style={{ border: "none" }}>
          <Link to="/process-automation-blog">
            <div className="blogpage4-grid" style={{ border: "none" }}>
              <img
                // src="assets/img/blog/SmoothTransition.png"
                src={`${config.baseUrl}/assets/img/blog/SmoothTransition.png`}
                alt="Smooth Transition"
                style={{ borderRadius: "3px" }}
              />

              <h6 className="blogpage4-h6" style={{ marginTop: "5%" }}>
                Implementing Process Automation: Best Practices for a Smooth
                Transition
              </h6>
              <p>
                Gain insights into the best practices for implementing process
                automation, ensuring a smooth transition and sustained benefits.
              </p>
            </div>
          </Link>
        </div>
      </div>

      <div className="row" style={{ padding: "0 5%" }}>
        <div className="col-md-4 " style={{ border: "none" }}>
          <Link to="/decision-making-blog">
            <div className="blogpage4-grid" style={{ border: "none" }}>
              <img
                // src="assets/img/blog/BusinessOperations.png"
                src={`${config.baseUrl}/assets/img/blog/BusinessOperations.png`}
                alt="Business Operations"
                style={{ borderRadius: "3px" }}
              />

              <h6 className="blogpage4-h6" style={{ marginTop: "5%" }}>
                How GenAI Agents Are Enhancing Decision-Making in Business
                Operations
              </h6>
              <p>
                Explore the impact of Generative AI agents in transforming
                decision-making processes and driving efficiency in business
                operations.
              </p>
            </div>
          </Link>
        </div>
        <div className="col-md-4 " style={{ border: "none" }}>
          <div className="blogpage4-grid" style={{ border: "none" }}>
            <img
              // src="assets/img/blog/UXDesign.png"
              src={`${config.baseUrl}/assets/img/blog/UXDesign.png`}
              alt="Designing for User Delight"
              style={{ borderRadius: "3px" }}
            />

            <h6 className="blogpage4-h6" style={{ marginTop: "5%" }}>
              Designing for User Delight: Key Principles of Effective UX Design
            </h6>
            <p>
              Learn the fundamental principles of UX design that contribute to
              creating delightful and intuitive user experiences.
            </p>
          </div>
        </div>
        <div className="col-md-4 " style={{ border: "none" }}>
          <div className="blogpage4-grid" style={{ border: "none" }}>
            <img
              // src="assets/img/blog/IntegrationStrategy.png"
              src={`${config.baseUrl}/assets/img/blog/IntegrationStrategy.png`}
              alt="Software Integration"
              style={{ borderRadius: "3px" }}
            />

            <h6 className="blogpage4-h6" style={{ marginTop: "5%" }}>
              Top 5 Best Practices for Successful Software Integration
            </h6>
            <p>
              Follow these essential best practices to ensure seamless
              integration of software systems, minimizing disruptions and
              maximizing functionality.
            </p>
          </div>
        </div>
      </div>

      <div className="row  " style={{ padding: "0 5%" }}>
        <div className="col-md-4 " style={{ border: "none" }}>
          <div className="blogpage4-grid" style={{ border: "none" }}>
            <img
              // src="assets/img/blog/IntelligentProcessAutomation.jpg"
              src={`${config.baseUrl}/assets/img/blog/IntelligentProcessAutomation.jpg`}
              alt="Intelligent Process Automation"
              style={{ borderRadius: "3px" }}
            />

            <h6 className="blogpage4-h6" style={{ marginTop: "5%" }}>
              Streamlining Business Operations with Intelligent Process
              Automation
            </h6>
            <p>
              Discover how intelligent process automation can optimize business
              workflows, reduce costs, and improve overall efficiency.
            </p>
          </div>
        </div>
        <div className="col-md-4 " style={{ border: "none" }}>
          <div className="blogpage4-grid" style={{ border: "none" }}>
            <img
              // src="assets/img/blog/ProjectManagement.png"
              src={`${config.baseUrl}/assets/img/blog/ProjectManagement.png`}
              alt="Choosing the Right Methodology for Your Project Management"
              style={{ borderRadius: "3px" }}
            />

            <h2 className="blogpage4-h6" style={{ marginTop: "5%" }}>
              Choosing the Right Methodology for Your Project Management
            </h2>
            <p>
              Understand various project management methodologies to select the
              best approach for your team's success and project requirements.
            </p>
          </div>
        </div>
        <div className="col-md-4 " style={{ border: "none" }}>
          <div className="blogpage4-grid" style={{ border: "none" }}>
            <img
              // src="assets/img/blog/live.jpg"
              src={`${config.baseUrl}/assets/img/blog/live.jpg`}
              alt="Growth and Culture"
              style={{ borderRadius: "3px", width: "100%" }}
            />

            <h6 className="blogpage4-h6" style={{ marginTop: "5%" }}>
              GROWTH & CULTURE
            </h6>
            <p>
              53 Key Ecommerce Statistics: Trends, Insights, and Predictions
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogpagefour;
