// import React from "react";
// import BlogGroup from "../components/BlogGroup";
// import Breadcrumb from "../components/Breadcrumb";
// import Footer from "../components/Footer";
// import NavBar from "../components/NavBar";
// import BannerOne from "../components/BannerOne";
// import AboutAreaOne from "../components/AboutAreaOne";
// import FaqAreaOne from "../components/FaqAreaOne";
// import Stopthink from "../components/Stopthink";
// import Map from "../components/Map";
// import ServiceAreaOne from "../components/ServiceAreaOne";
// import Uber from "../components/Uber";
// import MainPage1 from "../components/MainPage1";
// const Blog = () => {
//   return (
//     <>
//       {/* Navigation Bar */}
//       {/* <NavBar style={{textcolor: 'black'}} /> */}
//       {/* <BannerOne /> */}
//       {/* <NavBar style={{ background: 'black' }} /> */}
//       <NavBar className="custom-navbar" />

//       <MainPage1 />
//       {/* Navigation Bar */}
//       {/* <Breadcrumb title={"Blog"} /> */}

//       {/* Blog Group */}
//       {/* <BlogGroup /> */}

//       {/* Footer One */}
//       <Footer />
//     </>
//   );
// };

// export default Blog;

import React from "react";

const WebpageEmbed = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        src="https://testfuzionest.framer.website/"
        title="Embedded Webpage"
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    </div>
  );
};

export default WebpageEmbed;
