import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!cookies.cookieConsent) {
      setShowBanner(true);
    }
  }, [cookies]);


  const handleAcceptCookies = async () => {
    setCookie("cookieConsent", "true", { path: "/", maxAge: 31536000 }); // 1 year
    setShowBanner(false);
    await collectUserInfo();
  };

  const collectUserInfo = async () => {
    const userInfo = {
      ipAddress: await getIpAddress(),
      browser: navigator.userAgent,
      language: navigator.language,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      consentGivenAt: new Date().toISOString(),
      platform: navigator.platform,
      cookieEnabled: navigator.cookieEnabled,
      onlineStatus: navigator.onLine,
      vendor: navigator.vendor,
    };

    console.log("User Info Collected:", userInfo);

    try {
      await axios.post("https://api.fuzionest.com/cookies", userInfo);
    } catch (error) {
      console.error("Error sending user info:", error);
    }
  };

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return "Unknown";
    }
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-consent-banner">
      <p>
        We use cookies to improve your experience on our site. By using our
        site, you accept our cookie policy.
      </p>
      <button onClick={handleAcceptCookies}>Accept All Cookies</button>
    </div>
  );
};

export default CookieConsent;
