import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import config from "../../utils/config";

const videoData = [
  {
    src: `${config.baseUrl}/assets/img/landing/cloudData.webp`,
    title: "Elite Multi-Tenancy: Superior Server Orchestration and Security",
    description:
      "Experience the pinnacle of multi-tenant applications, featuring top-tier server orchestration and robust security protocols for unparalleled performance.",
    url: "/elite-multi",
  },
  {
    src: `${config.baseUrl}/assets/img/landing/consulting.webp`,
    title: "Strategic fusion: consulting meets vision",
    description:
      "Integrating expert consulting with visionary goals to drive success",
    url: "/strategic-fuzion",
  },
  {
    src: `${config.baseUrl}/assets/img/landing/backOffice.webp`,
    title: "Rapid Customization - Accelerated ERP and Back Office Solutions",
    description:
      "Deploy your tailored ERP or back office system quickly, featuring robust Role-Based Access Control for enhanced security and efficiency",
    url: "/rapid-customization",
  },
  {
    src: `${config.baseUrl}/assets/img/landing/genie.webp`,
    title: "Smart Automation: Boosting Workforce Efficiency with GEN-AI",
    description:
      "Transform your business processes using GEN-AI, and multiply workforce productivity through advanced automation",
    url: "/smart-automation",
  },
];

const Services = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleHover = useCallback(
    (index) => {
      if (!isMobile) {
        setHoveredIndex(index === hoveredIndex ? null : index);
      }
    },
    [hoveredIndex, isMobile]
  );

  useEffect(() => {
    AOS.init({ duration: 600 });

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="services-container mb-0">
      <div className="row no-gutter mb-0">
        {videoData.map((video, index) => (
          <div
            key={index}
            className="col-md-6"
            data-aos={index === 2 || index === 3 ? "fade-up" : "fade"}
            data-aos-delay={index === 2 || index === 3 ? "200" : "0"}
          >
            <div
              className="video-container"
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={() => handleHover(index)}
            >
              <img
                src={video.src}
                className="video-background"
                alt={video.title}
                loading="lazy"
              />
              <div
                className={`content-overlay ${
                  index === 0 || index === 1 ? "overlay-light" : "overlay-dark"
                } ${isMobile ? "show-content" : ""}`}
                data-aos="fade-in"
                data-aos-delay={
                  hoveredIndex === index || isMobile ? "200" : "0"
                }
              >
                <h4 data-aos="fade-up">{video.title}</h4>

                {hoveredIndex === index || isMobile ? (
                  <>
                    <p className="description" data-aos="fade-up">
                      {video.description}
                    </p>
                    <Link to={video.url}>
                      <button
                        className="btn btn-white border-radius-0"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        Learn More
                      </button>
                    </Link>
                  </>
                ) : (
                  !isMobile && <div className="description-placeholder"></div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
