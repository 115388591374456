import React from "react";

const FlutterBlog = () => {
  return (
    <>
      {/* ================== BannerOne Start ==================*/}
      <div
        className="banner-area bg-relative banner-area-1 pd-bottom-100 bg-cover"
        style={{
          backgroundColor: "#202021",
          backgroundSize: "cover",
          height: "797px",
        }}
      >
        {/* <img
          className="animate-img-home"
          // src="assets/img/rsl/5.svg"
          alt="Flutter Packages"
          id="rsl-image"
          style={{ height: "65%" }}
        /> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="banner-inner">
                <h2
                  className="title6-rsl"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                  style={{
                    color: "white",
                    fontWeight: "600",
                    width: "100%",
                    marginBottom: '0'
                  }}
                >
                  Exploring the Power of Flutter Packages and Isolates
                </h2>
                <p
                  className=""
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                  style={{ color: "white", fontSize: "30px" }}
                >
                  A comprehenssive guide with Flutter Packages and Isolates
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-8 offset-xl-2 offset-lg-1">
              <div
                className="banner-thumb mt-5 mt-lg-0 ms-4 ms-lg-0"
                data-aos="fade-left"
                data-aos-delay="100"
                data-aos-duration="1500"
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== BannerOne End ==================*/}
    </>
  );
};

export default FlutterBlog;
